(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-statistic/assets/route-statistic.js') >= 0) return;  svs.modules.push('/components/tipsen/route-statistic/assets/route-statistic.js');
"use strict";

const {
  useRouteMatch
} = ReactRouterDOM;
const {
  tipsenLazy
} = svs.components.tipsen.tipsenLazy;
const {
  ErrorBoundary
} = svs.components.errorBoundary;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  SportEventStatus
} = svs.components.sport.sportEventCommon;
const {
  useContext
} = React;
const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  useDrawSearchParams
} = svs.components.tipsen.drawProvider.hooks;
const {
  useMediaQuery
} = svs.components.lbUtils.useMediaQuery;
const {
  STATISTIC_SIDEBAR_QUERY
} = svs.components.tipsen.constants;
const {
  Sportinfo
} = svs.components.sportinfo.sportinfo;
const {
  ContentHandler
} = svs.components.sportInfo.contentHandler;
const {
  EmptyNotice
} = svs.component.tipsenEmptyNotice;
const {
  TipsenLink
} = svs.components.tipsen.tipsen_link;
const {
  SideNavResize
} = svs.components.tipsen.sideNavResize;
const {
  useSelector
} = ReactRedux;
const {
  selectLeagueFromSportEvent,
  selectSportEvent
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  selectors: matchInfoSelectors,
  utils: matchInfoUtils
} = svs.components.sportinfo.matchInfoRedux;
const {
  productIds
} = svs.utils.products;
const RouteStatistic = _ref => {
  var _sportEvent$sportEven;
  let {
    matchId,
    branding,
    bigStattabAreaClassName,
    drawNumber,
    hasMaskedOutcomes,
    setCoupon,
    shouldSaveDrawParams = false,
    shouldIncludeExtraStats,
    productId
  } = _ref;
  const match = useRouteMatch();
  const {
    drawNumber: drawNumberFromContext,
    eventNumber: _eventNumber,
    productId: productIdFromContext
  } = useContext(DrawContext);
  const eventNumber = _eventNumber || 1;
  const drawNumberToUse = drawNumber || drawNumberFromContext;
  const productIdToUse = productId || productIdFromContext;
  const searchParams = new URLSearchParams(useDrawSearchParams(productIdToUse, drawNumberToUse, eventNumber, shouldSaveDrawParams));
  const isWideDevice = useMediaQuery(STATISTIC_SIDEBAR_QUERY);
  if (!isWideDevice) {
    searchParams.set('openStatistic', '');
  }
  const basePath = match.url.endsWith('/') ? match.url : "".concat(match.url, "/");
  const league = useSelector(state => selectLeagueFromSportEvent(state, matchId));
  const sportEvent = useSelector(state => selectSportEvent(state, matchId));
  const betEventKey = matchInfoUtils.createBetEventKey(productIdToUse, drawNumberToUse, eventNumber);
  const betEvent = useSelector(state => matchInfoSelectors.selectBetEvent(state, betEventKey));
  const menuOptions = {
    hiddenScrollbar: true,
    useScrollFaders: true,
    useScrollHelpers: true
  };
  const classNames = ['route-play-statistic'];
  if (hasMaskedOutcomes) {
    classNames.push('pg_route_statistic--hidden');
  }
  return React.createElement(SideNavResize, null, hasMaskedOutcomes && React.createElement("div", {
    className: "pg_route_statistic__hidden_info"
  }, React.createElement("div", {
    className: "pg_route_statistic__hidden_info__header"
  }, "Matchen \xE4r or\xE4ttad"), "F\xF6r att inte f\xF6rst\xF6ra sp\xE4nningen s\xE5 d\xF6ljer vi det h\xE4r inneh\xE5llet f\xF6r dig tills matchen blivit r\xE4ttad."), React.createElement("div", {
    className: classNames.join(' ')
  }, React.createElement(ErrorBoundary, null, React.createElement(ContentHandler, {
    matchId: matchId,
    productId: productIdToUse,
    shouldIncludeExtraStats: shouldIncludeExtraStats,
    sportEventStatus: (_sportEvent$sportEven = sportEvent === null || sportEvent === void 0 ? void 0 : sportEvent.sportEventStatus) !== null && _sportEvent$sportEven !== void 0 ? _sportEvent$sportEven : SportEventStatus.NotStarted,
    sportType: league === null || league === void 0 ? void 0 : league.sportType
  }, _ref2 => {
    let {
      menuItems,
      content,
      lazyComponentsMap,
      matchStateProps
    } = _ref2;
    return React.createElement(Sportinfo, {
      activeTabBasePaths: [TipsenModuleGameUrl.SYSTEM, TipsenModuleGameUrl.TIPS_SM, TipsenModuleGameUrl.TIPS_EM, "".concat(productId === productIds.BOMBEN ? TipsenModuleGameUrl.MY_BETS2 : TipsenModuleGameUrl.MY_BETS, "/:wagerId(\\d+)"), "".concat(TipsenModuleGameUrl.RESULT, "/:date([0-9]{4}-[0-9]{2}-[0-9]{2})?")],
      appendTabSearchParams: searchParams,
      basePath: basePath,
      branding: branding,
      content: content,
      drawNumber: drawNumberToUse,
      eventNumber: eventNumber,
      eventTypeId: betEvent === null || betEvent === void 0 ? void 0 : betEvent.eventTypeId,
      FallbackComp: EmptyNotice,
      isWideDevice: isWideDevice,
      lazyComponentsMap: lazyComponentsMap,
      lazyLoader: tipsenLazy,
      matchId: matchId,
      matchState: matchStateProps.matchState,
      menuItems: menuItems,
      menuOptions: menuOptions,
      productId: productIdToUse,
      setCoupon: setCoupon,
      sportType: matchStateProps.sportType,
      tabAreaClassName: bigStattabAreaClassName,
      tabComponent: TipsenLink
    });
  }))));
};
setGlobal('svs.components.tipsen.routeStatistic.RouteStatistic', RouteStatistic);

 })(window);